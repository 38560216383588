import { ExternalProvider } from "@ethersproject/providers";
import { BigNumber } from "ethers";
import store from "../store";
import { AuthModel } from "../models/auth-model";

export const bigIntReplacer = (key: string, value: any) => {
  if (typeof value === "bigint") {
    return value.toString();
  }
  return value;
};

export const numberToDecimal = (value: number, decimal: number) => {
  // return value * 10 ** decimal;
  return value * Math.pow(10, decimal);
};

export const decimalToNumber = (value: number, decimal: number) => {
  // return value / 10 ** decimal;
  return value / Math.pow(10, decimal);
};

// export const numberToDecimalNew = (value: number, decimal: number) => {
//   const stringValue = value.toString();
//   const decimalPosition = stringValue.indexOf('.');

//   // If decimal position not found, add decimal scale zeroes
//   if (decimalPosition === -1) {
//     return `${stringValue}${'0'.repeat(decimal)}`;
//   }

//   // If decimal position found, adjust decimal scale
//   const integerPart = stringValue.slice(0, decimalPosition);
//   let decimalPart = stringValue.slice(decimalPosition + 1);

//   // Ensure decimal part length is at least decimal
//   if (decimalPart.length < decimal) {
//     decimalPart += '0'.repeat(decimal - decimalPart.length);
//   } else {
//     decimalPart = decimalPart.slice(0, decimal);
//   }

//   return `${integerPart}${decimalPart}`;
// };
// export const numberToDecimalNew = (value: number, decimal: number) => {
//   const result = value * 10 ** decimal;
//   return Math.round(result); // Round the result to the nearest integer
// };

export const numberToSpecifiedDecimalPlaces = (
  value: string,
  decimal: number
) => {
  const numberString = value.toString();

  // Check if the number already has 9 or more decimal places
  const decimalIndex = numberString.indexOf(".");
  if (decimalIndex !== -1 && numberString.length - decimalIndex > 10) {
    // If it has more than 9 decimal places, truncate it to 9 decimal places

    return parseFloat(parseFloat(value).toFixed(decimal));
  } else {
    // Otherwise, return the original number without padding
    return numberString;
  }
};