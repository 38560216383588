import React, { useEffect, useState } from "react";
import Header from "../comman_component/Header";
import Footer from "../comman_component/Footer";
import {
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import { useDispatch, useSelector } from "react-redux";
import { AuthModel } from "../models/auth-model";
import { Alert, AlertColor, CircularProgress, Snackbar } from "@mui/material";
import {
  getMyMsgoTokenBalance,
  receiveInGameCoin,
  receiveMsgoToken,
  setWeb3Provider,
} from "../utils/web3";
import {
  getCoinTokenPrice,
  getMsgoTokenLivePrice,
  getMsgoTokenLivePrice_new,
  getMyCoins,
  saveSwapLog,
  updateCoins,
  updateProfile,
} from "../networking/networkCalls";
import {
  decimalToNumber,
  numberToDecimal,
  numberToSpecifiedDecimalPlaces,
} from "../utils/utils";
import { authActions } from "../store/auth";
import { AppDispatch } from "../store";

enum SwapType {
  InGameCoinToMsgoToken,
  MsgoTokenToInGameCoin,
}

export default function Dapp() {
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<{
    msgoToken: number;
    inGameCoin: number;
  }>({ msgoToken: -1, inGameCoin: -1 });
  const [swapType, setSwapType] = useState(SwapType.InGameCoinToMsgoToken);
  const [msgoTokenBalance, setMsgoTokenBalance] = useState<number>(0);
  const [inGameCoinBalance, setInGameCoinBalance] = useState<number>(0);
  const [inGameCoinUnitPriceInUSD, setInGameCoinUnitPriceInUSD] =
    useState<number>(0);
  const [msgoTokenUnitPriceInUSD, setMsgoTokenUnitPriceInUSD] =
    useState<number>(0);

  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const { open } = useWeb3Modal();
  const dispatch = useDispatch<AppDispatch>();

  const [snackBarState, setSnackBarState] = useState<{
    show: boolean;
    msg: string;
    severity: AlertColor;
  }>({
    show: false,
    msg: "",
    severity: "success",
  });

  // @ts-ignore
  const authData: AuthModel = useSelector((state) => state.auth.authData);

  const isFormDataValid: () => boolean = () => {
    for (const key in formData) {
      if (
        formData[key] === null ||
        formData[key] === undefined ||
        `${formData[key]}`.trim() === ""
      ) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    const updateWalletAddress = async () => {
      await updateProfile(address);
    };

    if (isConnected && address) {
      // set provider in ethers
      setWeb3Provider(walletProvider);
      // update address in db
      updateWalletAddress();
    } else {
      setWeb3Provider(undefined);
    }
  }, [isConnected, address, walletProvider, dispatch]);

  useEffect(() => {
    const getInGameCoinPriceData = async () => {
      const res = await getCoinTokenPrice();

      if (res && res.data.success) {
        const { data } = res.data;

        // get unit price of in-game coin in USD
        setInGameCoinUnitPriceInUSD(data["in_game_coin_unit_price"]);
      }
    };

    const getMsgoTokenPriceData = async () => {
      // const res = await getMsgoTokenLivePrice();
      // if (res.status === 200) {
      //   const price = `${res.data.data["attributes"]["token_prices"]["0x80d956943ecf48b4f8dd761e95af7e622a72f715"]}`;

      //   setMsgoTokenUnitPriceInUSD(parseFloat(price));
      // }
      const res = await getMsgoTokenLivePrice_new();
      if (res.status === 200) {
        const price = `${res.data.priceUSD}`;

        setMsgoTokenUnitPriceInUSD(parseFloat(price));
      }
    };

    getInGameCoinPriceData();
    getMsgoTokenPriceData();
    getMyInGameCoinData();
  }, []);

  const getMyInGameCoinData = async () => {
    const res = await getMyCoins();

    if (res && res.data.success) {
      const { data } = res.data;

      setInGameCoinBalance(data["coins"]);
    }
  };

  useEffect(() => {
    getMyMsgoTokenBalanceData();
  }, [address, swapType]);

  const getMyMsgoTokenBalanceData = async () => {
    const balance = await getMyMsgoTokenBalance();

    if (balance) {
      setMsgoTokenBalance(balance);

      // if user had previously entered quantity of msgo token of his previous wallet
      // then check if the newly selected wallet has that amount or greater than previous wallet
      if (swapType === SwapType.MsgoTokenToInGameCoin) {
        if (numberToDecimal(formData.msgoToken, 9) > msgoTokenBalance) {
          const data = { ...formData };
          setFormData(data);
        }
      }
    }
  };

  useEffect(() => {
    const data = { ...formData };

    if (swapType === SwapType.InGameCoinToMsgoToken) {
      const quantityOfMsgoToken =
        (formData.inGameCoin * inGameCoinUnitPriceInUSD) /
        msgoTokenUnitPriceInUSD;

      // since every gainer has to pay 5% tax of how much msgo token they get,
      // we will give 95% of quantityOfMsgoToken (msgo token)
      data["msgoToken"] = quantityOfMsgoToken * 0.95;

      console.log("data[msgoToken] -> ", data["msgoToken"]);

      setFormData(data);
    }
  }, [formData.inGameCoin, swapType]);

  useEffect(() => {
    const data = { ...formData };

    if (swapType === SwapType.MsgoTokenToInGameCoin) {
      data["inGameCoin"] =
        (formData.msgoToken * msgoTokenUnitPriceInUSD) /
        inGameCoinUnitPriceInUSD;

      setFormData(data);
    }
  }, [formData.msgoToken, swapType]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string
  ) => {
    const data = { ...formData };

    if (swapType === SwapType.InGameCoinToMsgoToken) {
      if (field === "inGameCoin") {
        if (parseFloat(e.target.value) > inGameCoinBalance) {
          data[`${field}`] = inGameCoinBalance;
        } else {
          // data[`${field}`] = parseFloat(e.target.value);

          // @ts-ignore
          // data[`${field}`] = e.target.value;
          // convert number to only 9 decimal places if user enters more than 9 decimals
          data[`${field}`] = numberToSpecifiedDecimalPlaces(e.target.value, 9);
        }
      }
    }

    if (swapType === SwapType.MsgoTokenToInGameCoin) {
      if (field === "msgoToken") {
        if (numberToDecimal(parseFloat(e.target.value), 9) > msgoTokenBalance) {
          data[`${field}`] = decimalToNumber(msgoTokenBalance, 9);
        } else {
          // @ts-ignore
          data[`${field}`] = e.target.value;
        }
      }
    }

    setFormData(data);
  };

  // console.log("inGameCoinBalance ----> ", inGameCoinBalance);

  const changeSwapType = () => {
    setSwapType((prev) =>
      prev === SwapType.InGameCoinToMsgoToken
        ? SwapType.MsgoTokenToInGameCoin
        : SwapType.InGameCoinToMsgoToken
    );
  };

  const updateCoinsInGlobalState = (coins: number) => {
    const userData: AuthModel = JSON.parse(JSON.stringify(authData));
    userData.coins = coins;

    localStorage.setItem("authData", JSON.stringify(userData));

    dispatch(authActions.setAuthData(authData));

    setInGameCoinBalance(coins);
  };

  const swapTokens = async () => {
    if (!isFormDataValid()) {
      setSnackBarState({
        show: true,
        msg: "Please fill all the required fields",
        severity: "error",
      });
      return;
    }

    if (swapType === SwapType.InGameCoinToMsgoToken) {
      // min $10 worth in-game-coin is required
      if (inGameCoinUnitPriceInUSD * formData.inGameCoin < 10) {
        setSnackBarState({
          show: true,
          msg: "Minimum $10 worth of in-game-coin required",
          severity: "error",
        });
        return;
      }
    }

    if (swapType === SwapType.MsgoTokenToInGameCoin) {
      // min $10 worth in-game-coin is required
      if (msgoTokenUnitPriceInUSD * formData.msgoToken < 10) {
        setSnackBarState({
          show: true,
          msg: "Minimum $10 worth of msgo token required",
          severity: "error",
        });
        return;
      }
    }

    if (!isConnected) return open();

    setLoading(true);

    switch (swapType) {
      case SwapType.InGameCoinToMsgoToken:
        {
          // call smart contract method to transfer msgo token from owner's wallet to user's
          // upon successful response, hit the api to update transaction and coin
          const { success, txReceipt } = await receiveMsgoToken(
            // msgoTokenUnitPrice,
            msgoTokenUnitPriceInUSD,
            // inGameCoinUnitPrice,
            inGameCoinUnitPriceInUSD,
            formData.inGameCoin
          );

          if (success) {
            // Get updated msgo token
            getMyMsgoTokenBalanceData();

            // Set updated in game coins in local state
            const updatedInGameCoinsalance =
              authData.coins - formData.inGameCoin;

            setInGameCoinBalance(updatedInGameCoinsalance);

            // update in game coins in global state
            updateCoinsInGlobalState(updatedInGameCoinsalance);

            // update coins in db
            updateCoins({ coins: formData.inGameCoin, isCoinsToAdd: false });

            // add blockchain log in db
            saveSwapLog({
              from: "COIN",
              to: "MSGO_COIN",
              from_amount: formData.inGameCoin,
              to_amount: formData.msgoToken,
              blockchain_trnx_receipt: JSON.stringify(txReceipt),
            });

            setSnackBarState({
              show: true,
              msg: "Swapping was succeesfull",
              severity: "success",
            });
          } else {
            setSnackBarState({
              show: true,
              msg: "Something went wrong, please try again later",
              severity: "error",
            });
          }

          setLoading(false);
        }
        break;
      case SwapType.MsgoTokenToInGameCoin:
        {
          // call smart contract method to transfer msgo token from user's wallet to rumblego
          // upon successful response, hit the api to update transaction and coin
          const { success, txReceipt } = await receiveInGameCoin(
            // msgoTokenUnitPrice,
            msgoTokenUnitPriceInUSD,
            // inGameCoinUnitPrice,
            inGameCoinUnitPriceInUSD,
            formData.msgoToken
          );

          if (success) {
            // Get updated msgo token
            getMyMsgoTokenBalanceData();

            // Set updated in game coins in local state
            const updatedInGameCoinsalance =
              authData.coins + formData.inGameCoin;

            setInGameCoinBalance(updatedInGameCoinsalance);

            // update coins in global state
            updateCoinsInGlobalState(updatedInGameCoinsalance);

            // update coins in db
            updateCoins({ coins: formData.inGameCoin, isCoinsToAdd: true });

            // add blockchain log in db
            saveSwapLog({
              from: "MSGO_COIN",
              to: "COIN",
              from_amount: formData.msgoToken,
              to_amount: formData.inGameCoin,
              blockchain_trnx_receipt: JSON.stringify(txReceipt),
            });

            setSnackBarState({
              show: true,
              msg: "Swapping was succeesfull",
              severity: "success",
            });
          } else {
            setSnackBarState({
              show: true,
              msg: "Something went wrong, please try again later",
              severity: "error",
            });
          }

          setLoading(false);
        }
        break;
    }
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBarState({ ...snackBarState, show: false });
  };

  return (
    <>
      <Header />
      <Snackbar
        open={snackBarState.show}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={snackBarState.severity}
          sx={{ width: "100%" }}
        >
          {snackBarState.msg}
        </Alert>
      </Snackbar>
      <main>
        <section
          className="blueBG"
          style={{
            position: "relative",
          }}
        >
          <div className="px-4 py-5 col-lg-6 mx-auto text-center">
            <div className="container bg-light p-5 rounded-5">
              <h2>Token Swapper</h2>
              <p>
                Here you can swap your $MSGO tokens for RumbleGO's in-game
                currency GoCoins and vice versa!
              </p>
              <p>
                Use GoCoins in RumbleGO to compete in paid matches, then come
                back here to swap your GoCoins for $MSGO!
              </p>
              <p
                style={{
                  color: "#4facfe",
                }}
              >
                Please note that a 5% tax applies when swapping in-game GO coins
                for MSGO tokens.
              </p>
              <p>Note: "$10 swap minimum"</p>
              <div className="input-container">
                {swapType === SwapType.MsgoTokenToInGameCoin ? (
                  <>
                    <label id="msgoLabel" htmlFor="msgoAmount" className="mb-2">
                      <img
                        src="/assets/images/MsgoNewLogoDarkIcon.png"
                        id="msgoIcon"
                        alt="MSGO Icon"
                      />
                      MSGO Amount: ({msgoTokenBalance / Math.pow(10, 9)})
                    </label>
                    <input
                      className="input-field"
                      type="number"
                      id="msgoAmount"
                      placeholder="Enter MSGO amount"
                      value={`${formData.msgoToken >= 0 ? formData.msgoToken : ""
                        }`}
                      onChange={(
                        e: React.ChangeEvent<
                          HTMLInputElement | HTMLTextAreaElement
                        >
                      ) => handleInputChange(e, "msgoToken")}
                    />
                    <div className="arrow-button" onClick={changeSwapType}>
                      ⇅
                    </div>
                    <label
                      id="goCoinsLabel"
                      htmlFor="goCoinsAmount"
                      className="mb-2"
                    >
                      <img
                        src="/assets/images/gcoin.png"
                        id="goCoinsIcon"
                        alt="GoCoins Icon"
                      />
                      GoCoins Amount: ({inGameCoinBalance})
                    </label>
                    <input
                      className="input-field"
                      type="number"
                      id="goCoinsAmount"
                      placeholder="Enter GoCoins amount"
                      value={`${formData.inGameCoin >= 0 ? formData.inGameCoin : ""
                        }`}
                      onChange={(
                        e: React.ChangeEvent<
                          HTMLInputElement | HTMLTextAreaElement
                        >
                      ) => handleInputChange(e, "inGameCoin")}
                    />
                  </>
                ) : (
                  <>
                    <label
                      id="goCoinsLabel"
                      htmlFor="goCoinsAmount"
                      className="mb-2"
                    >
                      <img
                        src="/assets/images/gcoin.png"
                        id="goCoinsIcon"
                        alt="GoCoins Icon"
                      />
                      GoCoins Amount: ({inGameCoinBalance})
                    </label>
                    <input
                      className="input-field"
                      type="number"
                      id="goCoinsAmount"
                      placeholder="Enter GoCoins amount"
                      max={inGameCoinBalance}
                      value={`${formData.inGameCoin >= 0 ? formData.inGameCoin : ""
                        }`}
                      onChange={(
                        e: React.ChangeEvent<
                          HTMLInputElement | HTMLTextAreaElement
                        >
                      ) => handleInputChange(e, "inGameCoin")}
                    />
                    <div className="arrow-button" onClick={changeSwapType}>
                      ⇅
                    </div>
                    <label id="msgoLabel" htmlFor="msgoAmount" className="mb-2">
                      <img
                        src="/assets/images/MsgoNewLogoDarkIcon.png"
                        id="msgoIcon"
                        alt="MSGO Icon"
                      />
                      MSGO Amount: ({msgoTokenBalance / Math.pow(10, 9)})
                    </label>

                    <input
                      className="input-field"
                      type="number"
                      id="msgoAmount"
                      placeholder="Enter MSGO amount"
                      value={`${formData.msgoToken >= 0 ? formData.msgoToken : ""
                        }`}
                      onChange={(
                        e: React.ChangeEvent<
                          HTMLInputElement | HTMLTextAreaElement
                        >
                      ) => handleInputChange(e, "msgoToken")}
                    />
                  </>
                )}
              </div>
              {loading && <CircularProgress />}
              {!loading && (
                <button className="swap-button" onClick={swapTokens}>
                  Swap Tokens
                </button>
              )}
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
