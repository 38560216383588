import axios, { AxiosResponse } from "axios";
import { BASE_URL } from "../constants/constants";
import store from "../store";
import { AuthModel } from "../models/auth-model";
import { setWeb3Provider } from "../utils/web3";
import { authActions } from "../store/auth";

const logoutOnStatus401 = (response: AxiosResponse<any, any>) => {
  if (response.status === 401 && (response?.data?.isInactive || response?.data?.isTokenInvalid)) {
    localStorage.clear();
    localStorage.setItem("login", "0");
    setWeb3Provider(undefined);

    store.dispatch(authActions.setLoginState(false))
    const authData: AuthModel = undefined;
    store.dispatch(authActions.setAuthData(authData));

    return;
  }
}

const postRequest = async (
  path: string,
  data: any
): Promise<AxiosResponse<any, any> | undefined> => {
  let response: AxiosResponse<any, any> = undefined;

  const state = store.getState();
  const authData: AuthModel = state.auth.authData;

  try {
    response = await axios.post(`${BASE_URL}${path}`, data, {
      headers: {
        Authorization: `Bearer ${authData?.token}`,
      },
    });

    logoutOnStatus401(response);

  } catch (err) {
    console.log(`ERROR :: postRequest :: ${path} :: ${err}`);
    response = err.response;

    logoutOnStatus401(response);

  } finally {
    return response;
  }
};

const patchRequest = async (
  path: string,
  data: any
): Promise<AxiosResponse<any, any> | undefined> => {
  let response: AxiosResponse<any, any> = undefined;

  const state = store.getState();
  const authData: AuthModel = state.auth.authData;

  try {
    response = await axios.patch(`${BASE_URL}${path}`, data, {
      headers: {
        Authorization: `Bearer ${authData?.token}`,
      },
    });

    logoutOnStatus401(response);
  } catch (err) {
    // console.log(`ERROR :: postRequest :: ${path} :: ${err}`);
    response = err.response;

    logoutOnStatus401(response);
  } finally {
    return response;
  }
};

const getRequest = async (
  path: string,
  data = {},
  baseUrl = BASE_URL
): Promise<AxiosResponse<any, any> | undefined> => {
  let response: AxiosResponse<any, any> = undefined;

  const state = store.getState();
  const authData: AuthModel = state.auth.authData;

  try {
    response = await axios.get(`${baseUrl}${path}`, {
      params: data,
      headers: {
        Authorization: `Bearer ${authData?.token}`,
      },
    });

    logoutOnStatus401(response);
  } catch (err) {
    // console.log(`ERROR :: getRequest :: ${path} :: ${err}`);
    response = err.response;

    logoutOnStatus401(response);
  } finally {
    return response;
  }
};

const deleteRequest = async (
  path: string
): Promise<AxiosResponse<any, any> | undefined> => {
  let response: AxiosResponse<any, any> = undefined;

  const state = store.getState();
  const authData: AuthModel = state.auth.authData;

  try {
    response = await axios.delete(`${BASE_URL}${path}`, {
      headers: {
        Authorization: `Bearer ${authData?.token}`,
      },
    });

    logoutOnStatus401(response);
  } catch (err) {
    // console.log(`ERROR :: postRequest :: ${path} :: ${err}`);
    response = err.response;

    logoutOnStatus401(response);
  } finally {
    return response;
  }
};

const login = async (data: { email: string; password: string }) => {
  const path = "/auth/login";
  return postRequest(path, data);
};

const signup = async (data: {
  userName: string;
  email: string;
  password: string;
}) => {
  const path = "/auth/register";
  return postRequest(path, data);
};

const updateProfile = async (walletAddress?: string) => {
  const path = "/auth/updateProfile";
  return postRequest(path, { walletAddress });
};

const changePassword = async (data: { oldPassword: string; newPassword: string }) => {
  const path = "/auth/changePassword";
  return postRequest(path, data);
};

const getNFTs = async () => {
  const path = "/avatar/getAvatarsDapp";
  return getRequest(path);
};

const getNFTsWithCategory = async () => {
  const path = "/avatar/getAvatarsWithCategoryDapp";
  return getRequest(path);
};

const getTokenIdByAvatar = async (data: { avatar_id: number }) => {
  const path = "/avatar/getAvailableTokenIdDapp";
  return getRequest(path, data);
};

const saveNFTLog = async (data: {
  avatar_id: number;
  token_id: number;
  blockchain_txreceipt: string;
}) => {
  const path = "/avatar/saveNftPurchaseLogDapp";
  return postRequest(path, data);
};

const getCoinTokenPrice = async () => {
  const path = "/MasterMsgoTokenCoinUnitPrice/getMsgoTokenCoinUnitPrice";
  return getRequest(path);
};

const getMyCoins = async () => {
  const path = "/auth/getAvailableCoinsOfUser";
  return getRequest(path);
};

const saveSwapLog = async (data: {
  from: string;
  to: string;
  from_amount: number;
  to_amount: number;
  blockchain_trnx_receipt: string;
}) => {
  const path = "/swapTransactionHistory/saveSwapTransactionHistory";
  return postRequest(path, data);
};

const updateCoins = async (data: { coins: number; isCoinsToAdd: boolean }) => {
  const path = "/swapTransactionHistory/coinMsgoExchange";
  return postRequest(path, data);
};

const getMsgoTokenLivePrice = async () => {
  const path = `https://api.geckoterminal.com/api/v2/simple/networks/bsc/token_price/${process.env.REACT_APP_TOKEN_SMART_CONTRACT_ADDRESS}`;
  return getRequest(path, null, "");
};

const getMsgoTokenLivePrice_new = async () => {
  const path = `https://explorer.pancakeswap.com/api/cached/tokens/v2/bsc/${process.env.REACT_APP_TOKEN_SMART_CONTRACT_ADDRESS}`;
  return getRequest(path, null, "");
};


export {
  login,
  signup,
  updateProfile,
  changePassword,
  getNFTs,
  getTokenIdByAvatar,
  saveNFTLog,
  getNFTsWithCategory,
  getCoinTokenPrice,
  getMyCoins,
  saveSwapLog,
  updateCoins,
  getMsgoTokenLivePrice,
  getMsgoTokenLivePrice_new
};
