import React from "react";
import "./App.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Dapp from "./pages/Dapp";
import Home from "./pages/Home";
import NFTMarketplace from "./pages/NFTMatketplace";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5/react";
import { ConfigOptions } from "@web3modal/ethers5/dist/types/src/utils/defaultConfig";
import Login from "./pages/auth/Login";
import Signup from "./pages/auth/Signup";
import { useSelector } from "react-redux";
import Password from "./pages/auth/Password";
import ResetPassword from "./pages/auth/ResetPassword";

// Set chains
// const testnet = {
//   chainId: 97,
//   name: "Smart Chain - Testnet",
//   currency: "tBNB",
//   explorerUrl: "https://testnet.bscscan.com",
//   rpcUrl: "https://data-seed-prebsc-1-s1.binance.org:8545/",
// };

const mainnet = {
  chainId: 56,
  name: "Binance Smart Chain",
  currency: "BNB",
  explorerUrl: "https://bscscan.com",
  rpcUrl: "https://bsc-dataseed1.binance.org",
};

// Create modal
const options: ConfigOptions = {
  metadata: {
    name: "Rumble Go Dapp",
    description: "Rumble Go Dapp for NFTs, Tokens, Coins",
    url: "",
    icons: [],
  },
  enableCoinbase: true,
  enableInjected: true,
  enableEIP6963: true,
  defaultChainId: 56,
};

createWeb3Modal({
  ethersConfig: defaultConfig(options),
  chains: [mainnet],
  featuredWalletIds: [
    "e7c4d26541a7fd84dbdfa9922d3ad21e936e13a7a0e44385d44f006139e44d3b",
    "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
    "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0",
  ],
  defaultChain: mainnet,
  projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID || "",
});

const App = () => {
  // @ts-ignore
  const isLoggedIn: boolean = useSelector((state) => state.auth.isLoggedIn);
  // @ts-ignore
  const isAuthorized: boolean = useSelector((state) => state.auth.isAuthorized);
  // const isAuthorized = true;

  return (
    <BrowserRouter>
      {/* <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/login"
          element={!isLoggedIn ? <Login /> : <Navigate to={"/"} />}
        />
        <Route
          path="/signup"
          element={!isLoggedIn ? <Signup /> : <Navigate to={"/"} />}
        />
        <Route path="/about" element={<Home />} />
        <Route path="/features" element={<Home />} />
        <Route
          path="/dapp"
          element={isLoggedIn ? <Dapp /> : <Navigate to={"/login"} />}
        />
        <Route
          path="/nft-marketplace"
          element={isLoggedIn ? <NFTMarketplace /> : <Navigate to={"/login"} />}
        />
      </Routes> */}

      <Routes>
        <Route path="/auth" element={<Password />} />
        <Route path="/reset_password" element={<ResetPassword />} />
        <Route
          path="/"
          element={isAuthorized ? <Home /> : <Navigate to={"/auth"} />}
        />
        <Route
          path="/login"
          element={
            isAuthorized ? (
              !isLoggedIn ? (
                <Login />
              ) : (
                <Navigate to={"/"} />
              )
            ) : (
              <Navigate to={"/auth"} />
            )
          }
        />
        <Route
          path="/signup"
          element={
            isAuthorized ? (
              !isLoggedIn ? (
                <Signup />
              ) : (
                <Navigate to={"/"} />
              )
            ) : (
              <Navigate to={"/auth"} />
            )
          }
        />
        <Route
          path="/about"
          element={isAuthorized ? <Home /> : <Navigate to={"/auth"} />}
        />
        <Route
          path="/features"
          element={isAuthorized ? <Home /> : <Navigate to={"/auth"} />}
        />
        <Route
          path="/dapp"
          element={
            isAuthorized ? (
              isLoggedIn ? (
                <Dapp />
              ) : (
                <Navigate to={"/login"} />
              )
            ) : (
              <Navigate to={"/auth"} />
            )
          }
        />
        {/* <Route
          path="/nft-marketplace"
          element={
            isAuthorized ? (
              isLoggedIn ? (
                <NFTMarketplace />
              ) : (
                <Navigate to={"/login"} />
              )
            ) : (
              <Navigate to={"/auth"} />
            )
          }
        /> */}
        <Route path="/nft-marketplace" element={<NFTMarketplace />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;

// Token Smart Contract Address : 0x44f87cB4c7fC1258731f44026379021D31092D9D
// Deployed From : 0x127E74e152b801196Ad9713A6e93E2faF0a5bf4B
// Chain BSC Testnet
